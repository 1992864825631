import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./Panel-Container"
import { Button } from "../layout/StyledComponents"
import { Fancybox } from "../Fancy-Box"

const MainContainer = styled.div`
  padding: 72px 0px;
  background: ${colors.primary1};
  .middle-image {
    margin: 72px 0px 25px;
  }
  .first-image {
    margin-bottom: 25px;
  }
  .middle-panel {
    margin: 25px 0px;
  }
  h4 {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: -1.56px;
    line-height: 48px;
    margin: 0;
  }
  button {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
  p {
    letter-spacing: -0.69px;
    line-height: 36px;
    font-size: 22px;
  }
  @media ${device.tablet} {
    .video-image {
      cursor: pointer;
      img {
        transition: 0.2s ease-in-out;
      }
    }
    .video-image:hover {
      img {
        transform: scale(0.99);
      }
    }
    img {
      width: 500px;
    }
  }
  @media ${device.laptop} {
    .last-text-container {
      width: 553px;
    }
    .last-image {
      position: relative;
      right: 100px;
    }
    .first-image {
      position: relative;
      right: 100px;
    }
    padding: 128px 0px 128px;
    img {
      width: 555px;
    }

    .first-text-container {
      h4 {
        margin-bottom: 25px;
      }
      width: 543px;
    }
    .middle-text-container {
      p {
        width: 447px;
      }

      h4 {
        margin-bottom: 25px;
      }
    }
  }
`

export const EcclesiaPanels = () => {
  return (
    <MainContainer>
      <PanelContainer>
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://vimeo.com/716493532"
        > */}
        <Fancybox options={{ infinite: false }}>
          <Button
            style={{ border: "none", background: "none" }}
            data-fancybox="gallery"
            data-src="https://vimeo.com/716493532"
          >
            <StaticImage
              className="video-image first-image"
              src="../../images/programs/ecclesia/man-at-church.png"
              placeholder="none"
              quality={100}
              alt="group attending church"
            />
            {/* </a> */}
          </Button>
        </Fancybox>
        <div className="first-text-container">
          <h4>Home is where you belong</h4>
          <p>
            Ecclesia comes from a Greek word meaning "those who are called out."
            At Ecclesia, we create a spiritual space that emphasizes
            authenticity and the radical welcoming of those who scarcely believe
            they belong–disenfranchised, discriminated against, or locked in
            clutches of poverty to break bread and provide a sabbath rest for
            those needing respite.
          </p>
          <Fancybox options={{ infinite: false }}>
            <Button
              data-fancybox="gallery"
              data-src="https://vimeo.com/716493532"
              color="yellow"
            >
              Watch & learn more
            </Button>
          </Fancybox>
        </div>
      </PanelContainer>
      <PanelContainer reverse>
        <StaticImage
          className="middle-image"
          src="../../images/programs/ecclesia/people-collage.png"
          placeholder="none"
          quality={100}
          alt="group of people"
        />
        <div className="middle-text-container">
          <h4>Fill your cup</h4>
          <p>
            Central to our community is the core belief that in every human
            heart, an unfilled gap exists. An incomplete search or imperfection
            that can’t be made perfect. This gap is filled or complimented in an
            embodied, interconnected community and in the belief in something
            more significant and beyond ourselves.
          </p>
        </div>
      </PanelContainer>
      <PanelContainer>
        <StaticImage
          className="last-image"
          src="../../images/programs/ecclesia/tea-collage.png"
          placeholder="none"
          quality={100}
          alt="group of people"
        />
        <div className="last-text-container">
          <h4>Our spirituali-TEA communities</h4>
          <p>
            Ecclesia blends spirituality with technology, education, and arts to
            create all-inclusive secular and faith-based groups where
            historically minoritized communities learn from one another, harness
            their social capital, and find community.
          </p>
          <p style={{ color: `${colors.dark3}`, fontWeight: "900" }}>
            We like to call it spirituali-TEA
          </p>
          {/* <Button color="yellow">Watch & learn more</Button> */}
        </div>
      </PanelContainer>
    </MainContainer>
  )
}
